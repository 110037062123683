<template>
  <div class="CollegeRealName">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">实名认证企业学堂</span>
      </div>
      <el-form ref="form" :model="form" label-width="150px" :rules="rules">
        <el-form-item label="主体性质" prop="tenantCategory">
          <el-radio-group v-model="form.tenantCategory" @change="changeRadio">
            <el-radio :label="0">企业</el-radio>
            <el-radio :label="1">个人</el-radio>
            <el-radio :label="2">学院</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="form.tenantCategory == 0">
          <el-form-item label="企业/机构名称" prop="tenantName">
            <el-input
              v-model="form.tenantName"
              placeholder="请输入企业/机构名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="credentials">
            <el-input
              v-model="form.credentials"
              placeholder="请选择统一社会信用代码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="企业/机构所属行业" prop="belongIndustry">
            <el-select v-model="form.belongIndustry" placeholder="请选择企业/机构所属行业">
              <el-option
                v-for="item in industry"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在地区" prop="addressCode">
            <el-cascader
              ref="myCascader"
              v-model="form.addressCode"
              :options="total"
              :props="{
                value: 'label',
              }"
              placeholder="请选择所在地区"
              @change="handleAddressChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item ref="pic" label="企业营业执照" prop="businessLicense" class="license">
            <upload-image
              v-model="form.businessLicense"
              :upload-img="$refs.pic"
              :width-size="155"
              :height-size="192"
            ></upload-image>
            <div class="explain">（需要加盖企业公章）</div>
            <div class="extension">图片尺寸为155*192，支持扩展名：.png .jpg .jpeg</div>
          </el-form-item>
        </div>
        <div v-if="form.tenantCategory == 1">
          <el-form-item label="真实姓名" prop="contactName">
            <el-input v-model="form.contactName" placeholder="请输入真实姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="身份号码" prop="credentials">
            <el-input v-model="form.credentials" placeholder="请输入身份号码" clearable></el-input>
          </el-form-item>
          <el-form-item label="常驻地区" prop="addressCode">
            <el-cascader
              ref="myCascader"
              v-model="form.addressCode"
              :options="total"
              :props="{
                value: 'label',
              }"
              placeholder="请选择常驻地区"
              @change="handleAddressChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="身份证正反面" prop="identity" class="cardID">
            <div class="cardIDWrap">
              <upload-image
                v-model="form.idCardFrontImage"
                :width-size="240"
                :height-size="151"
              ></upload-image>
              <upload-image v-model="form.idCardBackImage" class="backCard"></upload-image>
            </div>
            <div class="extension">图片尺寸为240*151，支持扩展名：.png .jpg .jpeg</div>
          </el-form-item>
        </div>
        <div v-if="form.tenantCategory == 2">
          <el-form-item label="院校名称" prop="tenantName">
            <el-input v-model="form.tenantName" placeholder="请输入院校名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="专业方向" prop="professionalDirection">
            <el-input
              v-model="form.professionalDirection"
              rows="7"
              type="textarea"
              show-word-limit
              maxlength="128"
              placeholder="请输入专业方向"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="addressCode">
            <el-cascader
              ref="myCascader"
              v-model="form.addressCode"
              :options="total"
              :props="{
                value: 'label',
              }"
              placeholder="请选择所在地区"
              @change="handleAddressChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="证明文件" prop="collegeCertificate" class="collegeCertificate">
            <div class="cardIDWrap">
              <upload-image v-model="form.collegeCertificate"></upload-image>
            </div>
            <div class="explain">（需要加盖公章）</div>
            <div class="download" @click="downloadTemp">下载范本</div>
            <div class="extension">支持扩展名：.png .jpg .jpeg</div>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button @click="previousStep">上一步</el-button>
          <el-button type="primary" :loading="loading" class="submitBtn" @click="submit"
            >确认提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
const industry = [
  { key: 0, value: '未知' },
  { key: 1, value: '农、林、牧、渔业' },
  { key: 2, value: '采矿业' },
  { key: 3, value: '制造业' },
  { key: 4, value: '电力、燃气及水的生产和供应业' },
  { key: 5, value: '建筑业' },
  { key: 6, value: '交通运输' },
  { key: 7, value: '仓储和邮政业' },
  { key: 8, value: '批发和零售业' },
  { key: 9, value: '住宿和餐饮业' },
  { key: 10, value: '金融业' },
  { key: 11, value: '房地产业' },
  { key: 12, value: '租赁和商务服务业' },
  { key: 13, value: '科学研究、技术服务和地质勘查业' },
  { key: 14, value: '水利、环境和公共设施管理业' },
  { key: 15, value: '居民服务和其他服务业' },
  { key: 16, value: '教育 ' },
  { key: 17, value: '卫生、社会保障和社会福利业' },
  { key: 18, value: '文化、体育和娱乐业' },
]
import to from 'await-to'
import { addtenant } from '@/api/create'
import UploadImage from '@/components/UploadImage'
import total from '@/assets/address/totalCity'
export default {
  components: {
    UploadImage,
  },
  data() {
    var validateCard = (rule, value, callback) => {
      if (!this.form.idCardFrontImage && !this.form.idCardBackImage) {
        callback(new Error('请输入身份证照片'))
      } else if (!this.form.idCardFrontImage) {
        callback(new Error('请输入身份证正面'))
      } else if (!this.form.idCardBackImage) {
        callback(new Error('请输入身份证反面'))
      } else {
        callback()
      }
    }
    return {
      industry,
      form: {
        tenantCategory: 0,
        identity: [],
        organizationId: this.$route.query.organizationId,
        credentialsCategory: 0,
      },
      rules: {
        tenantName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        businessLicense: [{ required: true, message: '请上传企业营业执照', trigger: 'change' }],
        professionalDirection: [{ required: true, message: '请输入专业方向', trigger: 'blur' }],
        credentials: [{ required: true, message: '请输入号码', trigger: 'blur' }],
        belongIndustry: [{ required: true, message: '请选择信息', trigger: 'blur' }],
        addressCode: [{ required: true, message: '请选择地区', trigger: 'change' }],
        contactName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        identity: [{ required: true, validator: validateCard, trigger: 'change' }],
        collegeCertificate: [{ required: true, message: '请上传证明文件', trigger: 'change' }],
      },
      total,
      qualificationOptions: [],
      loading: false,
    }
  },
  created() {},
  methods: {
    handleAddressChange(val) {
      console.log(val)
    },
    changeRadio(val) {
      this.form.credentialsCategory = val
      if (val == 2) this.form.credentialsCategory = ''
    },
    create() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          this.loading = true
          console.log(this.form)
          const [, err] = await to(
            addtenant({
              ...this.form,
              provinceName: this.form.addressCode[0],
              cityName: this.form.addressCode[1],
              districtName: this.form.addressCode[2],
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          this.previousStep()
        }
      })
    },
    submit() {
      this.create()
    },
    previousStep() {
      this.$router.push('/college/collegeSetting')
    },
    downloadTemp() {
      const href = '/证明文件范本.docx'
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.download = '证明文件范本'
      a.click()
      URL.revokeObjectURL(a.href)
    },
  },
}
</script>

<style scoped lang="scss">
.CollegeRealName {
  .license {
    position: relative;
    font-size: 14px;
    .explain {
      position: absolute;
      left: -140px;
      top: 20px;
      color: #909399;
    }
  }
  .collegeCertificate {
    position: relative;
    font-size: 14px;
    .explain {
      position: absolute;
      left: -110px;
      top: 20px;
      color: #909399;
    }
    .download {
      cursor: pointer;
      position: absolute;
      left: -70px;
      top: 50px;
      color: #ff7b33;
    }
  }
  .extension {
    color: #909399;
  }
  .submitBtn {
    margin-left: 100px;
  }
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  .cardID {
    ::v-deep .el-form-item__content {
      .cardIDWrap {
        display: flex;
      }
    }
  }
  .backCard {
    ::v-deep .uploader {
      margin-left: 20px;
    }
  }
  ::v-deep.uploader {
    height: 120px;
    .el-upload {
      width: 120px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 120px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
  .el-textarea {
    width: 350px;
  }
}
</style>
